import { createSlice } from "@reduxjs/toolkit";
import { setFeedback } from "./feedbackSlice";
import axios from "axios";
axios.defaults.withCredentials = true;

const initialState = {
  user: null,
  loading: false,
  error: null,
};

const userByIdSliceForSidemenu = createSlice({
  name: "userByIdForSidemenu",
  initialState,
  reducers: {
    getUserByIdStartForSidemenu(state) {
      state.loading = true;
      state.error = null;
    },
    getUserByIdSuccessForSidemenu(state, action) {
      state.loading = false;
      state.user = action.payload;
    },
    getUserByIdFailureForSidemenu(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { getUserByIdStartForSidemenu, getUserByIdSuccessForSidemenu, getUserByIdFailureForSidemenu } =
  userByIdSliceForSidemenu.actions;
export default userByIdSliceForSidemenu.reducer;

export const fetchUserByIdForSidemenu = (userId, token) => async (dispatch) => {
  try {
    dispatch(getUserByIdStartForSidemenu());

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/users/${userId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const user = response.data; // Assuming the user data is in the response's "data" property
    dispatch(getUserByIdSuccessForSidemenu(user));
  } catch (error) {
    dispatch(getUserByIdFailureForSidemenu(error.message));
    dispatch(
      setFeedback({
        status: "fail",
        message: error.response.data.message,
        code: error.response.status,
      })
    );
  }
};
