import { Box, Grid, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { changePassword } from "../../store/slices/changePasswordSlice";
import getTokenFromLocalStorage from "../../utils/token";
import { successToast } from "../../responses/successToast";
import { passwordformat } from "../../utils/constant";
import { displayErrorMessage1 } from "../../utils/constant";
import { commonerror } from "../../utils/errormessage";
import { passworderror } from "../../utils/errormessage";
import { passwordformaterror } from "../../utils/errormessage";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { RiArrowLeftLine } from "react-icons/ri";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [erromessage2, setErrorMessage2] = useState("");
  const [erromessage3, setErrorMessage3] = useState("");
  const [erromessage4, setErrorMessage4] = useState("");
  const [erromessage5, setErrorMessage5] = useState("");
  const passwordRegex = passwordformat();

  const cancelHandler = () => {
    navigate("/dashboard");
    setCurrentPassword("");
    setNewPassword("");
    setConfirmPassword("");
  };
  const submitHandler = async (event) => {
    event.preventDefault();
    let flag=0

    if (!currentPassword) {
      displayErrorMessage1("Please enter current password", setErrorMessage2);
      flag=1
    }

    if (!newPassword) {
      displayErrorMessage1("Please enter new password", setErrorMessage3);
      flag=1
    }
    if (!confirmPassword) {
      displayErrorMessage1("Please re-enter new password", setErrorMessage4);
      flag=1
    }

    if (newPassword && !passwordRegex.test(newPassword)) {
      displayErrorMessage1(passwordformaterror, setErrorMessage5);
      flag=1
    }

    if (newPassword && confirmPassword && newPassword !== confirmPassword) {
      displayErrorMessage1(
        "New and confirm password should be same",
        setErrorMessage5
      );
      flag=1
    }
    if(flag!==0){
      return
    }

    try {
      const token = getTokenFromLocalStorage();

      const credentials = {
        password: currentPassword,
        newPassword: newPassword,
      };
      const changePasswordResult = await dispatch(
        changePassword(credentials, token)
      );
      if (changePasswordResult.success) {
        successToast(changePasswordResult.message);
        navigate("/dashboard");
      } else {
      }
    } catch (error) {
      console.error("Password change failed", error);
    } finally {
      setCurrentPassword("");
      setNewPassword("");
      setConfirmPassword("");
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      submitHandler(event);
    }
  };

  return (
    <>
    <Box className="content-header">
    <Typography variant="h2" component="h2" className="title-with-btn">
          {' '}
            <Button
              variant='outlined'
              className='back-btn'
              startIcon={<RiArrowLeftLine />}
              disableRipple
              onClick={() =>
                navigate('/')
              }
            ></Button>{' '}
             Change Password
          </Typography>
    </Box>
      <Box className="modal-wrapper-change content-layout p-24">
        <Box variant="div" component="div" className="title">
          <Typography variant="h3" component="h3">
              Strengthen your account security with a new password.
          </Typography>
        </Box>
        <Box variant="div" component="div" className="body">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box variant="div" component="div">
                <label>
                  Current Password
                  <Typography
                    variant="body1"
                    component="span"
                    className="asterisk"
                  >
                    *
                  </Typography>
                </label>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  placeholder="Enter Current Password"
                  size="small"
                  value={currentPassword}
                  onChange={(e) => {
                    setCurrentPassword(e.target.value);
                    if (e.target.value) {
                      setErrorMessage2("");
                    }
                  }}
                  type="password"
                  onKeyPress={handleKeyPress}
                />
              </Box>
             
              {erromessage2 && (
                <Typography variant="body1" component="p" className="error">
                  {erromessage2}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} mt={1}>
              <Box variant="div" component="div">
                <label>
                  New Password
                  <Typography
                    variant="body1"
                    component="span"
                    className="asterisk"
                  >
                    *
                  </Typography>
                </label>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  placeholder="Enter New Password"
                  size="small"
                  value={newPassword}
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                    if (e.target.value) {
                      setErrorMessage3("");
                      setErrorMessage5("");
                    }
                  }}
                  type="password"
                  onKeyPress={handleKeyPress}
                />
              </Box>
             
              {erromessage3 && (
                <Typography variant="body1" component="p" className="error">
                  {erromessage3}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} mt={1}>
              <Box variant="div" component="div">
                <label>
                  Confirm Password
                  <Typography
                    variant="body1"
                    component="span"
                    className="asterisk"
                  >
                    *
                  </Typography>
                </label>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  placeholder="Confirm Password"
                  size="small"
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                    if (e.target.value) {
                      setErrorMessage4("");
                      setErrorMessage5("");
                    }
                  }}
                  type="password"
                  onKeyPress={handleKeyPress}
                />
              </Box>
             
              {erromessage4 && (
                <Typography variant="body1" component="p" className="error">
                  {erromessage4}
                </Typography>
              )}
              {erromessage5 && (
                <Typography variant="body1" component="p" className="error">
                  {erromessage5}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box variant="div" component="div" className="footer-btn">
        <Box variant="div" component="div" className="btn-group">
          <Button
            variant="contained"
            size="large"
            className="primary-btn btn"
            disableRipple
            type="submit"
            onClick={submitHandler}
          >
            {" "}
            Submit
          </Button>
          <Button
            variant="contained"
            size="large"
            className="secondary-btn btn"
            disableRipple
            onClick={cancelHandler}
          >
            {" "}
            Cancel
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default ChangePassword;
