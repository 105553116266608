import React, { useEffect, useState } from "react";
import {
  RiHome5Line,
  RiFoldersLine,
  RiUserSettingsLine,
  RiLogoutBoxLine,
  RiBookOpenLine,
  RiMenu3Fill,
} from "react-icons/ri";
import {
  Backdrop,
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import logo from "../../assets/images/cs-logo-white.svg";
import { useNavigate, useLocation } from "react-router-dom";
import SignoutModal from "../../common/signoutmodal";
import "./sideMenu.scss";
import { handleSidebar } from "../../store/slices/drawerHandlerSlice";
import { useDispatch, useSelector } from "react-redux";
import { RiSettings2Line } from "react-icons/ri";
import * as settings from "../../styles/settings.scss"
import { fetchUserById } from "../../store/slices/userByIdSlice";
import getTokenFromLocalStorage from "../../utils/token";
import getIdFromLocalStorage from "../../utils/userid";
// MenuOptions=

const SideMenu = ({
  openSideMenu,
  setOpenSideMenu,
  isMobile,
  handleDrawerClose,
  isAdmin,
  isHrmanager,
  isHrexecutive,
  

}) => {
  const userId = getIdFromLocalStorage();
  const token = getTokenFromLocalStorage();
  let MenuOptions = [
    { label: "Dashboard", path: "/dashboard", icon: <RiHome5Line /> },
    { label: "Applications", path: "/applications", icon: <RiFoldersLine /> },
    {
      label: "Users",
      path: "/users",
      icon: <RiUserSettingsLine />,
    },
    {
      label: "Release Notes",
      path: "/release-notes",
      icon: <RiBookOpenLine />,
    },
    {
      label: 'Announcements',
      path: '/announcements',
      icon: <CampaignOutlinedIcon />,
  },
    {
      label: 'Settings',
      path: '/settings',
      icon: <RiSettings2Line />,
  },
    
  ];
  const [loading, setLoading] = useState(true);
  
  MenuOptions=isAdmin?MenuOptions:MenuOptions.filter((ele)=>ele.label!=="Users" && ele.label!=="Release Notes" && ele.label!=="Release Notes" && ele.label!=="Applications")
  const navigate = useNavigate();
  const location = useLocation();
  const sidebarState = useSelector((state) => state.sidebar.sidebarStatus);
  const dispatch = useDispatch();
  const handleNavigate = (path) => {
    navigate(path);
    if (window.innerWidth < 767) {
      setOpenSideMenu(false);
    }
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const isMenuActive = (itemPath) => {
    // console.log(location.pathname,"location.pathname")
    if(location.pathname==="/announcements-settings") return false
    return location.pathname.includes(itemPath);
  };
  useEffect(() => {
    const token = getTokenFromLocalStorage();
    dispatch(fetchUserById(userId, token))
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching user data:", error);
      });
  }, [dispatch, userId]);
  const userData = useSelector((state) => state.userById.user);
  MenuOptions=userData?.data?.allow_announcements||isAdmin?MenuOptions:MenuOptions.filter((ele)=>ele.label!=="Announcements" )
  MenuOptions=isAdmin?MenuOptions:MenuOptions.filter((ele)=>ele.label!=="Settings" );
  useEffect(() => {
    if (isMobile && sidebarState) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [sidebarState, isMobile]);

  return (
    <>
     { <Drawer
        anchor="left"
        className={`sidebar-wrapper ${
          sidebarState ? "sidebar-menu-open" : "sidebar-menu-closed"
        }`}
        variant="persistent"
        open={sidebarState}
        ModalProps={{
          keepMounted: true,
          disableEnforceFocus: true,
        }}
      >
        <Box className="list-wrap">
          <Box className="logo-box" sx={{ display: "flex" }}>
            <img
              src={logo}
              alt="logo-main"
              loading="lazy"
              className="large-logo"
              style={{ border: "0px solid white" }}
              onClick={() => navigate("/dashboard")}
            />
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={() => dispatch(handleSidebar(false))}
              className="toggle-btn"
              sx={{ border: "0px solid grey" }}
            >
              <RiMenu3Fill className="open-menu" fill="white" />
            </IconButton>
          </Box>
          <Box className="Menu-list-wrapper-sidebar">
            <List className="sidebar first-menu-list">
              
              {MenuOptions.map((item, index) => {
                
                return (
                  <ListItem
                    disablePadding
                    onClick={() => {
                      if (isMobile) {
                        dispatch(handleSidebar(false));
                        setTimeout(() => {
                          navigate(item.path);
                        }, 100);
                      } else {
                        navigate(item.path);
                      }
                    }}
                    key={`${item}-${index}`}
                    className={
                      location.pathname === item.path || isMenuActive(item.path) ||  (location?.pathname?.includes(
                        'announcements-settings'
                    ) &&
                        item?.path?.includes(
                            'settings'
                        )) ||
                    (location?.pathname?.includes(
                        'create-announcements'
                    ) &&
                        item?.path?.includes(
                            'announcements'
                        )) ||
                    (location?.pathname?.includes(
                        'view-announcements'
                    ) &&
                        item?.path?.includes(
                            'announcements'
                        )) ||
                    (location?.pathname?.includes(
                        'edit-announcements'
                    ) &&
                        item?.path?.includes(
                            'announcements'
                        )) 
                    
                        ? "activemenu"
                        : ""
                    }
                  >
                    <ListItemButton>
                      <ListItemIcon className="icon-wrap" title={item.label}>
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={item.label}
                        className="menu-text-wrap"
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
            <List className="sidebar last-menu-list">
              <ListItem>
                <ListItemButton onClick={handleOpen}>
                  <ListItemIcon className="icon-wrap">
                    <RiLogoutBoxLine />
                  </ListItemIcon>
                  <ListItemText primary={"Sign Out"} className="menu-text-wrap" />
                </ListItemButton>
              </ListItem>
            </List>
          </Box>
        </Box>
      </Drawer>}
      {isMobile && (
        <Backdrop        
          sx={{ zIndex: (theme) => theme.zIndex.drawer - 1 }}
          open={sidebarState}
          onClick={handleDrawerClose}
        />
      )}
      <SignoutModal open={open} handleClose={handleClose} />
    </>
  );
};

export default SideMenu;
