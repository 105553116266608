import React, { useLayoutEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import getTokenFromLocalStorage from "../utils/token";
import getuserDataFromCookies from "../utils/userData";
import { useDispatch, useSelector } from "react-redux";
import getIdFromLocalStorage from "../utils/userid";
import { fetchUserByIdForSidemenu } from "../store/slices/userByIdForSidemenuSlice";

const ProtectedRoute = ({ children, name }) => {
  const [isLoading, setIsLoading] = useState(true);
  const token = getTokenFromLocalStorage();
  const userData = getuserDataFromCookies();
  const userId = getIdFromLocalStorage();
  const dispatch = useDispatch();
  const userDataById = useSelector((state) => state?.userByIdForSidemenu?.user?.data);

  useLayoutEffect(() => {
    if (userId && token) {
      dispatch(fetchUserByIdForSidemenu(userId, token))
        .then(() => setIsLoading(false))
        .catch((error) => {
          setIsLoading(false);
          console.error("Error fetching user data:", error);
        });
    } else {
      setIsLoading(false);
    }
  }, [dispatch, userId, token]);

  if (!token) {
    return <Navigate to="/signin" replace={true} />;
  }

  if (isLoading && name === 'announcements') {
    // Display a loading indicator while data is being fetched
    return <div></div>;
  }

  // Access control based on module name and user permissions
  if (name === 'dashboard' || name === 'profile' || name === 'change-password') {
    return children;
  } 
  if (name === 'announcements' && (userDataById?.allow_announcements || userData?.role === 'admin')) {
    return children;
  } 
  if (userData?.role === 'admin') {
    return children;
  }

  // Fallback to no access if no conditions are met
  return <Navigate to="/noaccess" replace={true} />;
};

export default ProtectedRoute;
