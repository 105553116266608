import React from "react";

import { Routes, Route, Navigate } from "react-router-dom";

import Applications from "../pages/applications";
import UserManagement from "../pages/user-management";
import Applicationview from "../pages/applications/application/applicationview";
import Rolelist from "../pages/applications/role/rolelist";
import Rolecreate from "../pages/applications/role/rolecreate";
import Roleedit from "../pages/applications/role/roleedit";
import Usercreate from "../pages/user-management/usercreate";
import Edituser from "../pages/user-management/edituser";
import Userview from "../pages/user-management/userview";
import Login from "../pages/auth/login";
import ForgetPassword from "../pages/auth/forgetPassword";
import EmailSuccess from "../pages/auth/emailSuccess";
import ResetPassword from "../pages/auth/resetPassword";
import Layout from "../layout";
import UserProfileview from "../pages/profile/userprofileview";
import Dashboard from "../pages/dashboard";
import UserDashboard from "../pages/user-dashboard";
import { ToastContainer } from "react-toastify";
import ProtectedRoute from "./ProtectedRoute";
import getTokenFromLocalStorage from "../utils/token";
import Pagenotfound from "../pages/pagenotfound/index";
import ChangePassword from "../pages/auth/changePassword";
import ReleaseNotes from "../pages/releasenotes";
import AddEditReleaseNote from "../pages/releasenotes/AddEditReleaseNote";
import Settings from "../pages/settings";
import PostAnnouncements from "../pages/settings/PostAnnouncements";
import AnnouncementCreate from "../pages/settings/PostAnnouncements/createAnnouncements";
import AnnouncementEdit from "../pages/settings/PostAnnouncements/editAnnouncement";
import Announcementview from "../pages/settings/PostAnnouncements/viewAnnouncement";
import SidemenuAnnouncements from "../pages/settings/sidemenuAnnouncement";
import NoAccess from "../common/noAccess";
import { useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import getIdFromLocalStorage from "../utils/userid";
import { fetchUserByIdForSidemenu } from "../store/slices/userByIdForSidemenuSlice";

const PublicRouteWrapper = ({ children }) => {
  const token = getTokenFromLocalStorage();

  return !token ? children : <Navigate to="/dashboard" />
}

const Roots = () => {
  const token = getTokenFromLocalStorage();
  const dispatch = useDispatch();
  // const userId = getIdFromLocalStorage();
  // useLayoutEffect(() => {
  //   // const token = getTokenFromLocalStorage();
  //   dispatch(fetchUserByIdForSidemenu(userId, token))
  //     .then(() => {
  //       // setLoading(false);
  //     })
  //     .catch((error) => {
  //       // setLoading(false);
  //       console.error("Error fetching user data:", error);
  //     });
  // }, [dispatch, userId]);

  return (
    <>
      <ToastContainer />
      <Routes>
        <Route
          path="/signin"
          element={<PublicRouteWrapper><Login /></PublicRouteWrapper>}
        />
        <Route
          path="/"
          element={!token ? <Login /> : <Navigate to="/dashboard" />}
        />

        <Route
          path="/forgetpassword"
          element={!token ? <ForgetPassword /> : <Navigate to="/dashboard" />}
        />
        <Route
          path="/emailsuccess"
          element={!token ? <EmailSuccess /> : <Navigate to="/dashboard" />}
        />
        <Route
          path="/set-new-password"
          element={!token ? <ResetPassword /> : <Navigate to="/dashboard" />}
        />
        <Route
          path="/change-password"
          element={
            <ProtectedRoute name="change-password">
              <Layout>
                <ChangePassword />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute name="dashboard">
              <Layout>
                <Dashboard />
              </Layout>
            </ProtectedRoute>
          }
        />
        {/* <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Layout>
                <UserDashboard />
              </Layout>
            </ProtectedRoute>
          }
        /> */}
        <Route
          path="/applications"
          element={
            <ProtectedRoute name="applications">
              <Layout>
                <Applications />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/applications/:id/view"
          element={
            <ProtectedRoute name="applications">
              <Layout>
                <Applicationview />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/applications/:id/roles"
          element={
            <ProtectedRoute name="applications">
              <Layout>
                <Rolelist />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/applications/:id/roles/create"
          element={
            <ProtectedRoute name="applications">
              <Layout>
                <Rolecreate />
              </Layout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/applications/:id/roles/:id/edit"
          element={
            <ProtectedRoute name="applications">
              <Layout>
                <Roleedit />
              </Layout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/users"
          element={
            <ProtectedRoute name="users">
              <Layout>
                <UserManagement />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/users/create"
          element={
            <ProtectedRoute name="users">
              <Layout>
                <Usercreate />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/users/:userId/view"
          element={
            <ProtectedRoute name="users" >
              <Layout>
                <Userview />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/users/:userId/edit"
          element={
            <ProtectedRoute name="users">
              <Layout>
                <Edituser />
              </Layout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/profile"
          element={
            <ProtectedRoute name="profile">
              <Layout>
                <UserProfileview />
              </Layout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/release-notes"
          element={
            <ProtectedRoute name="release-notes">
              <Layout>
                <ReleaseNotes />
              </Layout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/release-notes/create"
          element={
            <ProtectedRoute name="release-notes">
              <Layout>
                <AddEditReleaseNote />
              </Layout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/release-notes/:releaseId/edit"
          element={
            <ProtectedRoute name="release-notes">
              <Layout>
                <AddEditReleaseNote />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/settings"
          element={
            <ProtectedRoute name="settings">
              <Layout>
                <Settings />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/announcements-settings"
          element={
            <ProtectedRoute name="settings">
              <Layout>
                <PostAnnouncements></PostAnnouncements>
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/announcements"
          element={
            <ProtectedRoute name="announcements">
              <Layout>
                <SidemenuAnnouncements></SidemenuAnnouncements>
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/create-announcements"
          element={
            <ProtectedRoute name="announcements">
              <Layout>
                <AnnouncementCreate></AnnouncementCreate>
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit-announcements/:id"
          element={
            <ProtectedRoute name="announcements">
              <Layout>
                <AnnouncementEdit></AnnouncementEdit>
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/view-announcements/:id"
          element={
            <ProtectedRoute  name="announcements">
              <Layout>
                <Announcementview></Announcementview>
              </Layout>
            </ProtectedRoute>
          }
        />

        <Route path="*" element={<Pagenotfound />} />
        <Route path="/noaccess" element={<NoAccess key="not-access" />} />
      </Routes>
    </>
  );
};

export default Roots;
