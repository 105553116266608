import {
  Box,
  Button,
  Drawer,
  Stack,
  Tab,
  TextField,
  Typography,
} from "@mui/material";
import {
  RiAddCircleLine,
  RiCloseCircleLine,
  RiCloseLine,
  RiFilter2Line,
  RiSearchLine,
} from "react-icons/ri";
import { DateRangePicker } from "rsuite";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import Announcements from "../../../common/AnnouncementCard";
import { useEffect, useLayoutEffect, useState } from "react";
import { fetchUserList } from "../../../store/slices/listAllUser";
import { useDispatch, useSelector } from "react-redux";
import getTokenFromLocalStorage from "../../../utils/token";
import getuserDataFromCookies from "../../../utils/userData";
import getIdFromLocalStorage from "../../../utils/userid";
const SidemenuAnnouncements = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let { state } = location;
  

  const userData = getuserDataFromCookies();
  const userId = getIdFromLocalStorage();
  const token = getTokenFromLocalStorage();
  if (state == null) {
    state = {
      status: 0,
      filterApplied: false,
      filterYear: "",
      filterMonth: { value: "", label: "" },
      filterType: { value: "", label: "" },
      filterPostedBy: { value: "", label: "" },
      filterPostedDate: "",
      page:1,
      rowsPerPage:25
    };
  }
  const [open, setOpen] = useState(false);
  const [filterYear, setFilterYear] = useState(state.filterYear);
  const [filterMonth, setFilterMonth] = useState(state.filterMonth.value);
  const [status, setStatus] = useState(state.status);
  const [filterType, setFilterType] = useState(state.filterType.value);
  const [filterApplied, setFilterApplied] = useState(state.filterApplied);
  const [dateFilter, setDateFilter] = useState(state.filterPostedDate);
  const [error, setError] = useState("");
  const [searchText, setSearchText] = useState("");
  const [tempSearchFilterText, setTempFilterText] = useState("");
  const [searchFilterText, setSearchFilterText] = useState("");
  const [page, setPage] = useState(state.page);
  const [rowsPerPage, setRowsPerPage] = useState(state.rowsPerPage);
  const [fromDate, setFromDate] = useState("");
  const [tempFromDate, setTempFromDate] = useState(
    state.filterPostedDate.length > 0 ? state.filterPostedDate[0] : ""
  );
  const [toDate, setToDate] = useState("");
  const [dateRange, setDateRange] = useState(
    state.filterPostedDate.length > 0
      ? `${state.filterPostedDate[0]},${state.filterPostedDate[1]}`
      : ""
  );
  const [tempToDate, setTempToDate] = useState(
    state.filterPostedDate.length > 0 ? state.filterPostedDate[1] : ""
  );
  const [tempFilterYear, setTempFilterYear] = useState(state.filterYear);
  const [tempFilterMonth, setTempFilterMonth] = useState(
    state.filterMonth.label == "" ? "" : state.filterMonth
  );
  const [tempFilterType, setTempFilterType] = useState(
    state.filterType.label == "" ? "" : state.filterType
  );
  const [isAppend, setIsAppend] = useState(false);
  const [isAdmin, setIsAdmin] = useState(userData?.role === "admin");
  const [isHrmanager, setIsHrmanager] = useState(
    userData?.app_roles?.HRMS == "HR Manager"
  );
  const [isHrexecutive, setIsHrexecutive] = useState(
    userData?.app_roles?.HRMS == "HR Executive"
  );
  const dispatch = useDispatch();

  const [tempFilterPostedBy, setTempFilterPostedBy] = useState(
    isAdmin
      ? state.filterPostedBy.label == ""
        ? ""
        : state.filterPostedBy
      : { value: userId }
  );
  const [filterPostedBy, setFilterPostedBy] = useState(
    isAdmin
      ? state.filterPostedBy.value
      : userId
  );
  const authorData = useSelector((state) => {
    return state.userList.userList?.data;
  });
  const formattedAuthorData = authorData?.map((item) => ({
    value: item.id,
    label: item.full_name,
  }));
  const handleDateChange = (date) => {
    if (date) {
      setTempFromDate(date[0]);
      setTempToDate(date[1]);
      setDateFilter(date);
    } else {
      // Handle the case where the date is null or undefined
      setTempFromDate(null);
      setTempToDate(null);
      setDateFilter(null);
    }
    setError("");
  };

  const onCloseDrawer = () => {
    setOpen(false);
  };

  const handleApplyFilter = async () => {
    if (
      !tempFilterYear &&
      !tempFilterMonth &&
      !tempFilterPostedBy &&
      !tempFilterType &&
      !tempSearchFilterText &&
      !tempFromDate &&
      !tempToDate
    ) {
      setError("Please select any one field");
      return;
    }
    // console.log(tempFromDate,tempToDate,"date is there")
    // Update main filter states with the temporary values
    setFilterYear(tempFilterYear);
    setFilterMonth(tempFilterMonth.value);
    isAdmin 
      ? setFilterPostedBy(tempFilterPostedBy.value)
      : setFilterPostedBy((value) => value);
    setFilterType(tempFilterType.value);

    setFilterApplied(true);
    setSearchFilterText(tempSearchFilterText);
    setFromDate(tempFromDate);
    setToDate(tempToDate);
    setDateRange(`${tempFromDate},${tempToDate}`);
    setOpen(false);
    setError("");
  };

  const currentYear = new Date().getFullYear();
  const yearOptions = Array.from(
    { length: currentYear - 2024 + 1 },
    (_, i) => ({ value: 2024 + i, label: (2024 + i).toString() })
  );

  useEffect(() => {
    setIsAdmin(userData?.role === "admin");
    setIsHrmanager(userData?.app_roles?.HRMS == "HR Manager");
    setIsHrexecutive(userData?.app_roles?.HRMS == "HR Executive");
  }, [userData]);

  useEffect(() => {
    window.addEventListener("beforeunload", () => {
      window.history.replaceState({}, "");
    });
    return () => {
      window.history.replaceState({}, "");
    };
  }, []);

  useLayoutEffect(() => {
    try {
      dispatch(fetchUserList(token,true));
    } catch (error) {
      console.error("Loading Data Failed", error);
    }
  }, []);

  useEffect(() => {
    setSearchText("");
  }, [tempFilterYear, tempFilterMonth, tempFilterPostedBy, tempFilterType]);
  function addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
  const startOfMonth = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    1
  );
  const endOfMonth = new Date(
    new Date().getFullYear(),
    new Date().getMonth() + 1,
    0
  );
  return (
    <>
      <Box className="content-header leave-module-wrapper">
        <Typography variant="h2" component="h2" className="title-with-btn">
          Announcements
          {filterApplied && (
            <>
              <Button
                variant="contained"
                size="large"
                className="outline-btn btn add-btn-filter"
                endIcon={<RiCloseLine />}
                disableRipple
                onClick={() => {
                  setFilterApplied(false);
                  setFilterMonth("");
                  setFilterYear("");
                  isAdmin 
                    ? setFilterPostedBy("")
                    : setFilterPostedBy((value) => value);
                  setFilterType("");
                  setTempFilterMonth("");
                  setTempFilterYear("");
                  isAdmin 
                    ? setTempFilterPostedBy("")
                    : setTempFilterPostedBy((value) => value);
                  setTempFilterType("");
                  setSearchFilterText("");
                  setDateFilter("");
                  setDateRange("");
                  setTempFilterText("");
                }}
              >
                Remove filter
              </Button>
            </>
          )}
        </Typography>
        <Box variant="div" component="div" className="content-header-right">
          <Box variant="div" component="div" className="search-bar">
            <RiSearchLine />
            <TextField
              fullWidth
              id="WFH search"
              label=""
              variant="outlined"
              size="small"
              placeholder="Search here"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            {searchText && (
              <RiCloseCircleLine
                className="cross-line"
                onClick={() => setSearchText("")}
              />
            )}
          </Box>
          <Button
            variant="contained"
            size="large"
            className="primary-btn btn add-btn"
            endIcon={<RiAddCircleLine />}
            disableRipple
            onClick={() => {
              navigate("/create-announcements", {
                state: {
                  status: status,
                  filterApplied: filterApplied,
                  filterYear: filterYear,
                  filterMonth: tempFilterMonth,
                  filterType: tempFilterType,
                  filterPostedBy: tempFilterPostedBy,
                  filterPostedDate: dateFilter,
                  page:page,
                  rowsPerPage:rowsPerPage,
                },
              });
            }}
          >
            Add
          </Button>
          <Button
            variant="contained"
            size="large"
            className="primary-btn btn add-btn"
            endIcon={<RiFilter2Line />}
            disableRipple
            onClick={() => setOpen(true)}
          >
            Filters
          </Button>
        </Box>
      </Box>
      <Box className="content-wrapper">
        <Announcements
          filterYear={filterYear}
          filterMonth={filterMonth}
          filterPostedBy={filterPostedBy}
          filterType={filterType}
          searchText={searchText}
          setSearchText={setSearchText}
          isAppend={isAppend}
          setIsAppend={setIsAppend}
          status={status}
          setStatus={setStatus}
          filterApplied={filterApplied}
          filterMonthNavigation={tempFilterMonth}
          filterTypeNavigation={tempFilterType}
          filterPostedByNavigation={tempFilterPostedBy}
          searchFilterText={searchFilterText}
          dateRange={dateRange}
          dateFilter={dateFilter}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      </Box>
      <Drawer
        className="side-drawer-2 report-side-drawer leave-module-wrapper"
        anchor="right"
        open={open}
        onClose={onCloseDrawer}
      >
        <Box className="sidebar-tab-wrapper-2">
          <Box className="close-btn">
            <RiCloseLine onClick={onCloseDrawer} />
          </Box>
          <Box className="sidebar-tab">
            <Tab
              className="tab-label-btn contact-label"
              label="Filters"
              disabled
            ></Tab>
          </Box>
        </Box>
        <Stack spacing={2} sx={{ padding: "24px !important" }}>
          <Box>
            <Typography variant="body1" component="label">
              Search Text
            </Typography>
            <TextField
              fullWidth
              id="WFH search"
              label=""
              variant="outlined"
              size="small"
              placeholder="Search here"
              value={tempSearchFilterText}
              onChange={(e) => {
                setTempFilterText(e.target.value);
                setError("");
              }}
            />
            {error && (
              <Typography variant="body1" component="p" className="error">
                {error}
              </Typography>
            )}
          </Box>
          <Box>
            <Typography variant="body1" component="label">
              Posted Date
            </Typography>
            <DateRangePicker
              // oneTap
              className="joining-date-picker"
              menuClassName="drawer-date-picker-container"
              block
              placeholder="Select Date Range"
              fullWidth
              onChange={handleDateChange}
              format="dd-MM-yyyy"
              value={dateFilter}
              renderExtraFooter={() => null}
              ranges={[]}
            />
            {error && (
              <Typography variant="body1" component="p" className="error">
                {error}
              </Typography>
            )}
          </Box>

          {/* <Box>
            <Typography variant="body1" component="label">
              Select Month
            </Typography>
            <Select
              placeholder="Select Month"
              name="Month"
              className="multi-select-field"
              options={[
                { value: 1, label: "January" },
                { value: 2, label: "February" },
                { value: 3, label: "March" },
                { value: 4, label: "April" },
                { value: 5, label: "May" },
                { value: 6, label: "June" },
                { value: 7, label: "July" },
                { value: 8, label: "August" },
                { value: 9, label: "September" },
                { value: 10, label: "October" },
                { value: 11, label: "November" },
                { value: 12, label: "December" },
              ]}
              classNamePrefix="select"
              theme={(theme) => ({
                ...theme,
                borderRadius: 10,
                colors: {
                  ...theme.colors,
                  primary25: "var(--secondary-text-color)",
                  primary: "#8CC714",
                },
              })}
              onChange={(e) => {
                console.log("onChange",e.value,e.label)
                setTempFilterMonth({ value: e.value, label: e.label });
                setError("");
              }}
              value={
                tempFilterMonth
                  ? {
                      label: tempFilterMonth.label,
                      value: tempFilterMonth.value,
                    }
                  : ""
              }
            />
            {error && (
              <Typography variant="body1" component="p" className="error">
                {error}
              </Typography>
            )}
          </Box> */}
          {/* <Box>
            <Typography variant="body1" component="label">
              Select Year
            </Typography>
            <Select
              placeholder="Select Year"
              name="year"
              className="multi-select-field"
              options={yearOptions}
              classNamePrefix="select"
              theme={(theme) => ({
                ...theme,
                borderRadius: 10,
                colors: {
                  ...theme.colors,
                  primary25: "var(--secondary-text-color)",
                  primary: "#8CC714",
                },
              })}
              onChange={(e) => {
                setTempFilterYear(e.value);
                setError("");
              }}
              value={
                tempFilterYear
                  ? { label: tempFilterYear, value: tempFilterYear }
                  : ""
              }
            />
            {error && (
              <Typography variant="body1" component="p" className="error">
                {error}
              </Typography>
            )}
          </Box> */}

          {(isAdmin) && (
            <Box>
              <Typography variant="body1" component="label">
                Posted By
              </Typography>
              <Select
                placeholder="Select Posted By"
                name="posted_by"
                className="multi-select-field"
                options={formattedAuthorData}
                classNamePrefix="select"
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 10,
                  colors: {
                    ...theme.colors,
                    primary25: "var(--secondary-text-color)",
                    primary: "#8CC714",
                  },
                })}
                value={
                  tempFilterPostedBy
                    ? {
                        value: tempFilterPostedBy.value,
                        label: tempFilterPostedBy.label,
                      }
                    : ""
                }
                onChange={(e) => {
                  setTempFilterPostedBy({ value: e.value, label: e.label });
                  setError("");
                }}
              />
              {error && (
                <Typography variant="body1" component="p" className="error">
                  {error}
                </Typography>
              )}
            </Box>
          )}
          <Box>
            <Typography variant="body1" component="label">
              Highlighted
            </Typography>
            <Select
              placeholder="Select Type"
              name="type"
              className="multi-select-field"
              classNamePrefix="select"
              options={[
                { value: "important", label: "Yes" },
                { value: "all", label: "No" },
              ]}
              theme={(theme) => ({
                ...theme,
                borderRadius: 10,
                colors: {
                  ...theme.colors,
                  primary25: "var(--secondary-text-color)",
                  primary: "#8CC714",
                },
              })}
              onChange={(e) => {
                setTempFilterType({ value: e.value, label: e.label });
                setError("");
              }}
              value={
                tempFilterType
                  ? { label: tempFilterType.label, value: tempFilterType.value }
                  : ""
              }
            />
            {error && (
              <Typography variant="body1" component="p" className="error">
                {error}
              </Typography>
            )}
          </Box>
          <Box className="filter-button-wrapper text-end">
            <Button
              variant="contained"
              size="large"
              type="submit"
              className="primary-btn btn add-btn mx-2"
              onClick={handleApplyFilter}
              disableRipple
            >
              Apply
            </Button>
            <Button
              variant="contained"
              size="large"
              className="secondary-btn btn ml-16"
              disableRipple
              onClick={() => {
                setOpen(false);
                setError("");
                setFilterApplied(false);
                setFilterMonth("");
                setFilterYear("");
                isAdmin 
                  ? setFilterPostedBy("")
                  : setFilterPostedBy((value) => value);
                setFilterType("");
                setTempFilterMonth("");
                setTempFilterYear("");
                isAdmin 
                  ? setTempFilterPostedBy("")
                  : setTempFilterPostedBy((value) => value);
                setTempFilterType("");
                setDateFilter("");
              }}
            >
              Cancel
            </Button>
          </Box>
        </Stack>
      </Drawer>
    </>
  );
};

export default SidemenuAnnouncements;
