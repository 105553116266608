import { Box, Grid, Typography } from "@mui/material";
import React, { useLayoutEffect } from "react";
import "./dashboard.scss";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getApplicationsData } from "../../store/slices/userDashboardSlice";
import getTokenFromLocalStorage from "../../utils/token";
import getIdFromLocalStorage from "../../utils/userid";
import { fetchUserList } from "../../store/slices/listAllUser";
const Dashboard = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.userDashboard);
  const authorData = useSelector((state)=>{return state.userList.userList?.data});
  const userId = getIdFromLocalStorage();
  const token = getTokenFromLocalStorage();
 
  async function getData() {
    try {
      dispatch(getApplicationsData(userId, token));
    } catch (error) {
      console.error("Loading Data Failed", error);
    }
  }
  useEffect(() => {
    getData();
  }, []);

  const clickApplicationHandler = (app_url) => {
    if (app_url) {
      window.open(app_url, "_blank");
    } else {
    }
  };

  return (
    <>
      <Box className="content-header">
        <Typography variant="h2" component="h2">
          Dashboard
        </Typography>
      </Box>
      <Box className="content-layout">
        <Box className="p-24">
          <Grid container spacing={3}>
            {data?.data?.application_details?.map((row) => {
              return (
                <Grid item lg={3} md={6} sm={12} xs={12}>
                    <Box className="dashboard-card-wrapper" onClick={() => clickApplicationHandler(row?.app_url)}>
                      <Box class="app-logo" variant="div" component="div">
                        <img src={row?.img_path} alt="app-logo" loading="lazy" />
                      </Box>
                      <Typography className='card-name' variant='h5' component='h5'>
                        {row?.application_name}
                      </Typography>
                    </Box>
                </Grid>
              );
            })}
            {!data?.data?.application_details.length && (
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography variant='h5' component='h5'>No Application here for you...</Typography>
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Dashboard;
