import React, { useState, useEffect } from "react";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  RiMenu3Fill,
  RiCloseFill,
  RiArrowRightSLine,
  RiLogoutBoxLine,
} from "react-icons/ri";
import { MdPublishedWithChanges } from "react-icons/md";
import { FiChevronRight, FiUser, FiLogOut } from "react-icons/fi";
import logo from "../../assets/images/cs-logo-white.svg";
import SiteLogo from "../../assets/images/sitelogo.png";
import userLogo from "../../assets/images/user.png";
import { useNavigate } from "react-router-dom";
import "./header.scss";
import SignoutModal from "../../common/signoutmodal";
import Favicon from "../../assets/images/favicon.ico";
import getuserDataFromCookies from "../../utils/userData";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IoApps } from "react-icons/io5";
import { handleSidebar } from '../../store/slices/drawerHandlerSlice';

const Header = ({ setOpenSideMenu, openSideMenu, isAdmin,isHrmanager,isHrexecutive,isAllowed }) => {
   const sidebarState = useSelector(state => state.sidebar.sidebarStatus);
    const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cookies] = useCookies();
  const [singOutModalOpen, setSingOutModalOpen] = useState(false);
  const [userData, setUserData] = useState(getuserDataFromCookies());
  const data = useSelector((state) => state.userDashboard);

  const handleOpenSingOutModal = () => setSingOutModalOpen(true);
  const hanldecloseSingOutModal = () => setSingOutModalOpen(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const renderMenuItems = () => {
    return data?.data?.application_details?.map((item, index) => {
      return (
        <Grid
          item
          xs={4}
          // p={1}
          sx={{
            "& a": {
              textDecoration: "none",
            },
          }}
        >
          <MenuItem
            key={index}
            onClick={handleClose}
            component={Link}
            to={item.app_url}
            target="_blank"
            sx={{
              // padding: "4px",
              "&:hover": {
                borderRadius: "12px",
                backgroundColor: "#f8f9fa",

                "& .MuiTypography-root": {
                  color: "black !important",
                },
              },
            }}
          >
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item>
                <img
                  src={item.img_link ? item.img_link : SiteLogo}
                  alt="logo-main"
                  loading="lazy"
                  height="32px"
                />
              </Grid>
              <Grid item>
                <Typography
                  sx={{
                    color: "white !important",
                    fontSize: "14px",
                  }}
                >
                  {item.app_name}
                </Typography>
              </Grid>
            </Grid>
          </MenuItem>
        </Grid>
      );
    });
  };

  useEffect(() => {
    const updatedCookieUserData = getuserDataFromCookies();
    setUserData(updatedCookieUserData);
  }, [cookies]);
  const handleLogoClick = () => {
    navigate("/dashboard");
  };

  return (
    <>
      <AppBar
        position="fixed"
        open={sidebarState}
        className={`navbar ${sidebarState ? "sidebar-open" : "sidebar-closed"}`}
      >
        <Toolbar>
         {(isAdmin||isAllowed)?  <Box variant="div" component="div" className="navbar-left" >
            {/* <img
              src={logo}
              alt="logo-main"
              loading="lazy"
              className="large-logo"
              onClick={handleLogoClick}
            />
            <img
              src={Favicon}
              alt="logo-main"
              loading="lazy"
              className="small-logo"
              onClick={() => navigate("/")}
            /> */}

            {(isAdmin||isAllowed)? (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={() => dispatch(handleSidebar(true))}
                className="toggle-btn"
              >
                <RiMenu3Fill className="open-menu" />
                <RiArrowRightSLine className="close-menu" />
                {/* {window.innerWidth < 767 && openSideMenu ? (
                  <RiCloseFill />
                ) : (
                  <>
                    <RiMenu3Fill className="open-menu" />
                    <RiArrowRightSLine className="close-menu" />
                  </>
                )} */}
              </IconButton>
            ) : null}
          </Box>:null}
          <Box variant="div" component="div" className="navbar-right">
            <Typography variant="h1" noWrap component="h1">
              Central System
            </Typography>

            <Box variant="div" component="div" className="user-info">
              <IoApps
                size={36}
                fillColor="black !important"
                id="basic-button"
                aria-controls={open2 ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open2 ? "true" : undefined}
                onClick={handleClick2}
                style={{ cursor: 'pointer', fill: '#232323' }}
              />
              <Menu
                id="basic-menu"
                anchorEl={anchorEl2}
                open={open2}
                onClose={handleClose2}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                sx={{
                  "& .MuiPaper-root": {
                    backgroundColor: "var(--black)",
                    borderRadius: "12px",
                    border: "2px solid white",
                    marginTop: "15px",
                    marginLeft: "-30px",
                    // minWidth: "270px",
                    width: '280px !important',
                  },
                }}
              >
                <Grid container p={1}>
                  {renderMenuItems()}
                </Grid>
              </Menu>
              <Avatar
                src={userData?.imgUrl}
                onClick={handleClick}
                style={{ height: '36px', width: '36px' , cursor:'pointer', border:'1px solid #8cc714' }}>
              </Avatar>

              <Menu
                className="account-dropdown-menu"
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.15))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{
                  horizontal: "right",
                  vertical: "top",
                }}
                anchorOrigin={{
                  horizontal: "right",
                  vertical: "bottom",
                }}
              >
                <Box className="user-details">
                  <Box
                    className="user-img"
                    sx={{
                      padding: `${userData?.imgUrl ? "0px" : "8px"}`,
                    }}
                  >
                    <img
                      src={userData?.imgUrl || userLogo}
                      alt="account img"
                      style={{ padding: !userData?.imgUrl ? "8px" : "" }}
                    />
                  </Box>
                  <Typography
                    variant="h5"
                    component="h5"
                    className="text-green user-name"
                    align="center"
                  >
                    {userData?.fname} {userData?.lname}
                  </Typography>
                  <Typography
                    variant="span"
                    component="span"
                    className="text-grey user-position"
                    align="center"
                  >
                    {userData?.role?.charAt(0).toUpperCase() +
                      userData?.role?.slice(1)}
                  </Typography>
                </Box>
                <hr className="divider-border" />
                <Box className="organization-details">
                  <Box className="org-img">
                    <img src={SiteLogo} alt="account img" />
                  </Box>
                  <Box className="org-text">
                    <Typography
                      variant="h5"
                      component="h5"
                      className="text-green user-name"
                      align="center"
                    >
                      {userData?.companyId === 1
                        ? "Crest Infosystems Pvt. Ltd."
                        : "Crest Skillserve Pvt. Ltd."}
                    </Typography>
                    <Typography
                      variant="span"
                      component="span"
                      className="text-grey user-position"
                      align="center"
                    >
                      Organization
                    </Typography>
                  </Box>
                </Box>
                <Box className="links">
                  <MenuItem
                    className="menu-link"
                    onClick={() => navigate("/profile")}
                  >
                    <ListItemIcon className="link-icon">
                      <FiUser />
                    </ListItemIcon>
                    My Account
                  </MenuItem>
                  <MenuItem
                    className="menu-link"
                    onClick={() => navigate("/change-password")}
                  >
                    <ListItemIcon className="link-icon">
                      <MdPublishedWithChanges />
                    </ListItemIcon>
                    Change Password
                  </MenuItem>
                  <MenuItem
                    className="menu-link"
                    onClick={handleOpenSingOutModal}
                  >
                    <ListItemIcon className="link-icon">
                      <RiLogoutBoxLine />
                    </ListItemIcon>
                    Sign Out
                  </MenuItem>
                </Box>
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <SignoutModal
        open={singOutModalOpen}
        handleClose={hanldecloseSingOutModal}
      />
    </>
  );
};

export default Header;
