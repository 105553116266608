import {
  Box,
  Button,
  Drawer,
  Grid,
  Stack,
  Tab,
  TextField,
  Typography,
} from "@mui/material";
import {
  RiAddCircleLine,
  RiCloseCircleLine,
  RiCloseLine,
  RiFilter2Line,
  RiSearchLine,
} from "react-icons/ri";
import { successToast } from "../../../responses/successToast";
import { RiArrowLeftLine, RiArrowDownSLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { useEffect, useLayoutEffect, useState } from "react";
import { fetchUserList } from "../../../store/slices/listAllUser";
import { useDispatch, useSelector } from "react-redux";
import getTokenFromLocalStorage from "../../../utils/token";
import { components } from 'react-select';
import axios from "axios"
const PostAnnouncements = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [filterYear, setFilterYear] = useState('');
  const [filterMonth, setFilterMonth] = useState('');
  const [filterPostedBy, setFilterPostedBy] = useState('');
  const [filterType, setFilterType] = useState('');
  const [filterApplied, setFilterApplied] = useState(false);
  const [error, setError] = useState('');
  const token = getTokenFromLocalStorage();
  const [searchText, setSearchText] = useState('');
  const [tempFilterYear, setTempFilterYear] = useState('');
  const [tempFilterMonth, setTempFilterMonth] = useState('');
  const [tempFilterPostedBy, setTempFilterPostedBy] = useState('');
  const [tempFilterType, setTempFilterType] = useState('');
  const [removeKeyId, setRemoveKeyId] = useState([]);
  const [allowedUsers,setAllowedUsers]=useState([]);
  const [options,setOptions]=useState([])

  const [loading,setLoading]=useState(true)
  const dispatch = useDispatch();

  const onCloseDrawer = () => {
    setOpen(false);
  };

  const authorData = useSelector((state) => state.userList.userList?.data);
  const formattedAuthorData = authorData?.map((item) => ({
    value: item.id,
    label: item.full_name,
  }));

  const generateRandomMembers = (count) => {
    const members = [];
    for (let i = 1; i <= count; i++) {
      members.push({
        value: `member-${i}`, // Unique value for each member
        label: `Member ${i}`, // Display name for each member
      });
    }
    return members;
  };

  const memberOptions = generateRandomMembers(10);

  useLayoutEffect(() => {
    try {
      dispatch(fetchUserList(token,false));
    } catch (error) {
      console.error("Loading Data Failed", error);
    }
  }, [dispatch, token]);

  const handleApplyFilter = async () => {
    if (!tempFilterYear && !tempFilterMonth && !tempFilterPostedBy && !tempFilterType) {
      setError('Please select at least one field');
      return;
    }

    // Update main filter states with the temporary values
    setFilterYear(tempFilterYear);
    setFilterMonth(tempFilterMonth.value);
    setFilterPostedBy(tempFilterPostedBy.value);
    setFilterType(tempFilterType.value);

    setFilterApplied(true);
    setOpen(false);
    setError('');
  };
const handleAllowedUserChange=()=>{
  axios.patch(`${process.env.REACT_APP_API_URL}/announcement/isAllowed`, {"userIdArray":options})
  .then(response => {
    successToast("Announcement Permissions Updated Successfully");
    navigate("/settings")
    // console.log(response.data);
  })
  .catch(error => {
    // Handle error
    console.error(error);
  });
}
// console.log(allowedUsers,"allowedUser")
useEffect(() => {
  const fetchAllowedUsers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/announcement/fetchAllowedUsers`
      );
      // console.log("response",response)
      setOptions(()=>response.data.data.map((option)=>option.value))
      setAllowedUsers(response.data.data); // Assuming response.data holds the list of allowed users
      setLoading(false); // Stop loading once data is fetched
    } catch (err) {
      console.error("Error fetching allowed users", err);
      setError(err); // Set error state
      setLoading(false); // Stop loading on error
    }
  };

  fetchAllowedUsers();
}, []); // Empty dependency array ensures it runs once on component mount
  useEffect(() => {
    setSearchText('');
  }, [tempFilterYear, tempFilterMonth, tempFilterPostedBy, tempFilterType]);
  const CustomClearIndicator = (props) => {
    return null; // This will hide the clear indicator
  };
  return (
    <>
      <Box className="content-header leave-module-wrapper">
        <Typography variant="h2" component="h2" className="title-with-btn">
        <Button
            variant="outlined"
            className="action-btn back-btn"
            onClick={() => navigate("/settings")}
            startIcon={<RiArrowLeftLine />}
            disableRipple
            // onClick handler
          ></Button>
          Announcements
          
          {/* {filterApplied && (
            <Button
              variant="contained"
              size="large"
              className="outline-btn btn add-btn-filter"
              endIcon={<RiCloseLine />}
              disableRipple
              onClick={() => {
                setFilterApplied(false);
                setFilterMonth('');
                setFilterYear('');
                setFilterPostedBy('');
                setFilterType('');
                setTempFilterMonth('');
                setTempFilterYear('');
                setTempFilterPostedBy('');
                setTempFilterType('');
              }}
            >
              Remove filter
            </Button>
          )} */}
        </Typography>
        <Box className="content-header-right">
          {/* <Box className="search-bar">
            <RiSearchLine />
            <TextField
              fullWidth
              id="search"
              variant="outlined"
              size="small"
              placeholder="Search here"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            {searchText && (
              <RiCloseCircleLine
                className="cross-line"
                onClick={() => setSearchText('')}
              />
            )}
          </Box> */}
          {/* <Button
            variant="contained"
            size="large"
            className="primary-btn btn add-btn"
            endIcon={<RiAddCircleLine />}
            disableRipple
            onClick={() => {
              navigate("/create-announcements");
            }}
          >
            Add
          </Button> */}
          {/* <Button
            variant="contained"
            size="large"
            className="primary-btn btn add-btn"
            endIcon={<RiFilter2Line />}
            disableRipple
            onClick={() => setOpen(true)}
          >
            Filters
          </Button> */}
        </Box>
      </Box>
      <Box className="content-layout">
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box className="card-wrapper">
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography variant="h3" component="h3" className="card-title">
                Select Users Authorized to Post Announcements
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Select
                  isMulti
                  name="additionalRecipients"
                  options={formattedAuthorData}
                  value={allowedUsers}
                  onChange={(selectedOptions, { removedValue }) => {
                    if (removedValue) {
                      const removedOptionId = removedValue.value; // assuming 'value' contains the ID
                      setRemoveKeyId((prev) => [...prev, removedOptionId]);
                      
                    }
                    // setAllowedUser((prev) => [...prev, removedOptionId]);
                    // Here you can handle the selected options
                  //  console.log(selectedOptions,"selectedOptions")
                    setOptions(()=>selectedOptions.map((option)=>option.value))
                    setAllowedUsers(selectedOptions)
                  }}
                  className="multi-select-field"
                  classNamePrefix="select"
                  placeholder="Select Additional Recipients"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 10,
                    colors: {
                      ...theme.colors,
                      primary25: "var(--secondary-text-color)",
                      primary: "#8CC714",
                    },
                  })}
                  components={{
                    ClearIndicator: CustomClearIndicator, // Replace default clear indicator with custom
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Box>
      <Drawer
        className="side-drawer-2 report-side-drawer leave-module-wrapper"
        anchor="right"
        open={open}
        onClose={onCloseDrawer}
      >
        <Box className="sidebar-tab-wrapper-2">
          <Box className="close-btn">
            <RiCloseLine onClick={onCloseDrawer} />
          </Box>
          <Box className="sidebar-tab">
            <Tab className="tab-label-btn contact-label" label="Filters" disabled />
          </Box>
        </Box>
        <Stack spacing={2} sx={{ padding: "24px !important" }}>
          <Box>
            <Typography variant="body1" component="label">
              Select Year
            </Typography>
            <Select
              placeholder="Select Year"
              name="year"
              className="multi-select-field"
              options={[
                { value: 2024, label: "2024" },
                { value: 2023, label: "2023" },
                { value: 2022, label: "2022" },
                { value: 2021, label: "2021" },
                { value: 2020, label: "2020" },
                { value: 2019, label: "2019" },
                { value: 2018, label: "2018" },
              ]}
              classNamePrefix="select"
              theme={(theme) => ({
                ...theme,
                borderRadius: 10,
                colors: {
                  ...theme.colors,
                  primary25: "var(--secondary-text-color)",
                  primary: "#8CC714",
                },
              })}
              onChange={(e) => {
                setTempFilterYear(e.value);
                setError('');
              }}
              value={tempFilterYear ? { label: tempFilterYear, value: tempFilterYear } : ''}
            />
            {error && (
              <Typography variant="body1" component="p" className="error">
                {error}
              </Typography>
            )}
          </Box>
          <Box>
            <Typography variant="body1" component="label">
              Select Month
            </Typography>
            <Select
              placeholder="Select Month"
              name="month"
              className="multi-select-field"
              options={[
                { value: 1, label: "January" },
                { value: 2, label: "February" },
                { value: 3, label: "March" },
                { value: 4, label: "April" },
                { value: 5, label: "May" },
                { value: 6, label: "June" },
                { value: 7, label: "July" },
                { value: 8, label: "August" },
                { value: 9, label: "September" },
                { value: 10, label: "October" },
                { value: 11, label: "November" },
                { value: 12, label: "December" },
              ]}
              classNamePrefix="select"
              theme={(theme) => ({
                ...theme,
                borderRadius: 10,
                colors: {
                  ...theme.colors,
                  primary25: "var(--secondary-text-color)",
                  primary: "#8CC714",
                },
              })}
              onChange={(e) => {
                setTempFilterMonth(e);
                setError('');
              }}
              value={tempFilterMonth}
            />
          </Box>
          <Box>
            <Typography variant="body1" component="label">
              Posted By
            </Typography>
            <Select
              placeholder="Select Posted By"
              name="postedBy"
              className="multi-select-field"
              options={formattedAuthorData}
              classNamePrefix="select"
              theme={(theme) => ({
                ...theme,
                borderRadius: 10,
                colors: {
                  ...theme.colors,
                  primary25: "var(--secondary-text-color)",
                  primary: "#8CC714",
                },
              })}
              onChange={(e) => {
                setTempFilterPostedBy(e);
                setError('');
              }}
              value={tempFilterPostedBy}
            />
          </Box>
          <Box>
            <Typography variant="body1" component="label">
              Type
            </Typography>
            <Select
              placeholder="Select Type"
              name="type"
              className="multi-select-field"
              options={[
                { value: "Info", label: "Info" },
                { value: "Warning", label: "Warning" },
                { value: "Error", label: "Error" },
              ]}
              classNamePrefix="select"
              theme={(theme) => ({
                ...theme,
                borderRadius: 10,
                colors: {
                  ...theme.colors,
                  primary25: "var(--secondary-text-color)",
                  primary: "#8CC714",
                },
              })}
              onChange={(e) => {
                setTempFilterType(e);
                setError('');
              }}
              value={tempFilterType}
            />
          </Box>
          <Button
            variant="contained"
            className="btn apply-filter-btn"
            onClick={handleApplyFilter}
          >
            Apply Filters
          </Button>
        </Stack>
      </Drawer>
      <Box className="footer-wrapper">
        <Button
          variant="contained"
          size="large"
          className="primary-btn btn w-120"
          disableRipple
          onClick={handleAllowedUserChange}
          // disabled={isButtonDisabled}
        >
          Save
        </Button>
        <Button
          variant="contained"
          size="large"
          className="secondary-btn btn w-120"
          disableRipple
          onClick={()=>navigate("/settings")}
        >
          Cancel
        </Button>
      </Box>
    </>
  );
};

export default PostAnnouncements;
